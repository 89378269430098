<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-row>
        <el-col :span="24" align="left">
          <el-button type="primary" @click="goBack">返回</el-button>
        </el-col>
      </el-row>
    </div>
    <el-form ref="perForm" :model="formData" :rules="rules" label-width="200px">
      <el-form-item label="logo" prop="logoId">
        <el-upload :limit="1" :data="postData" list-type="picture-card" show-file-list :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload" action="http://up-z0.qiniup.com" multiple :file-list="fileList"
          :on-remove="handleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="封面" prop="cover">
        <el-upload :limit="1" :data="postData1" list-type="picture-card" show-file-list :on-success="handleAvatarSuccess1"
          :before-upload="beforeAvatarUpload1" action="http://up-z0.qiniup.com" multiple :file-list="fileList1"
          :on-remove="handleRemove1">
          <i class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip">(只能上传,宽275px,高160px)</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="标题名称" prop="title">
        <el-input v-model="formData.title" placeholder="请输入标题名称" style="width: 50%" />
      </el-form-item>

      <el-form-item label="分类类型" prop="typeId">
        <el-select v-model="formData.typeId" placeholder="请选择" @change="selectChange">
          <el-option v-for="(item, index) in options" :key="index" :label="item.typeName" :value="item.typeId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="简介" prop="brief">
        <el-input v-model="formData.brief" type="textarea" placeholder="请输入简介(2-40个字)" style="width: 50%" />
      </el-form-item>
      <el-form-item label="专题" prop="topicIdArr">
        <el-select multiple v-model="formData.topicIdArr" placeholder="请选择专题" @change="selectChange1">
          <el-option v-for="(item, index) in res" :key="index" :label="item.topicName" :value="item.topicId">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="标签" prop="tags">
        <el-input v-model="formData.tags" placeholder="请输入标签名称(多个标签逗号分割)" style="width: 60%" @input="sc" />
      </el-form-item>

      <el-form-item label="上架状态" prop="upAction">
        <el-switch v-model="formData.upAction" active-text="定时上架" :active-value=1 inactive-text="默认不上架" :inactive-value=0
          @change="upchange">

        </el-switch>
      </el-form-item>


      <el-form-item label="上传时间" prop="upDateMsg" v-if="formData.upAction == 1">
        <el-date-picker v-model="formData.upDateMsg" type="datetime" placeholder="请输入上传时间" style="width:90%" format
          value-format="yyyy-MM-dd HH:mm" @change="change123">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="发布人名称" prop="publishUserName">
        <el-input v-model="formData.publishUserName" placeholder="请输入发布人名称" style="width: 50%" />
      </el-form-item>
      <el-form-item label="来源类型" prop="sourceType" v-if="!formData.articleId">
        <el-radio v-model="formData.sourceType" :label='1' @change="change">本地</el-radio>
        <el-radio v-model="formData.sourceType" :label="2" @change="change">外部链接</el-radio>

      </el-form-item>
      <el-form-item label="资讯详情" prop="content" v-if="formData.sourceType == 1">
        <div style="line-height: normal">
          <uEditor v-model="formData.content"></uEditor>
        </div>
      </el-form-item>
      <el-form-item label="外部链接" prop="urlMsg" v-if="formData.sourceType == 2">
        <el-input v-model="formData.urlMsg" style="width: 30%" />
      </el-form-item>
      <el-form-item label="排序" prop="sort" v-if="formData.articleId">
        <el-input v-model="formData.sort" style="width: 30%" />
      </el-form-item>
    </el-form>
    <el-row slot="footer" type="flex" justify="center">
      <el-col :span="6">
        <el-button size="small" type="primary" @click="btnOK">
          确定
        </el-button>
        <el-button size="small" @click="btnCancel">取消</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getTopics,
  addType,
  getUploadToken,
  getTypes,
  getArticles,
  addArticle,
  setArticleStatus,
  modifyArticle,
  delArticle,
} from "../../request/http";
export default {
  data() {
    return {

      editOradd: "",
      imageUrl: "",
      imageUrl1: "",

      postData: {
        token: "",
        key: "", //上传的Logo
      },
      postData1: {
        token: "",
        key: "", //上传的Logo
      },
      fileList: [],
      fileList1: [],
      res: [],
      options: [],
      formData: {
        logoId: "",
        typeId: "",
        publishUserName: "",
        sourceType: null,
        brief: "",
        topicIdArr: [],
        title: "",
        content: "",
        urlMsg: "",
        tags: '',
        upAction: 0,
        upDateMsg: '',
        cover: ''
      },
      rules: {
        title: [
          { required: true, message: "标题名称不能为空", trigger: "blur" },
        ],
        logoId: [{ required: true, message: "logo不能为空", trigger: "blur" }],
        // publishUserName: [
        //   { required: true, message: "发布人名称不能为空", trigger: "blur" },
        // ],
        sourceType: [
          { required: true, message: "来源类型不能为空", trigger: "blur" },
        ],
        upAction: [
          { required: true, message: "定时发布不能为空", trigger: "blur" },
        ],
        brief: [{ required: true, message: "简介不能为空", trigger: "blur" },],
        content: [
          { required: true, message: "资讯详情不能为空", trigger: "blur" },
        ],
        urlMsg: [
          { required: true, message: "外部链接不能为空", trigger: "blur" },
        ],
        upDateMsg: [
          { required: true, message: "上架时间不能为空", trigger: "blur" },
        ],
        typeId: [
          { required: true, message: "分类Id不能为空", trigger: "blur" },
        ],
        sort: [{ required: true, message: "排序不能为空", trigger: "blur" }],
      },


    };


  },
  created() {
    this.getList()
    this.getTopics()
    console.log(this.$route.query);
    //editOradd 传过来的为 0 或者 1
    this.editOradd = this.$route.query.editOradd == 0 ? false : true;
    if (this.$route.query.obj) {
      let val = this.$route.query.obj;
      if (val.logoId) {
        this.formData.logoId = val.logoId; //头像
        this.fileList.push({
          url: "http://file.innopinenut.com/" + val.logoId
        });
      }

      if (val.cover) {
        this.formData.cover = val.cover; //头像
        this.fileList1.push({
          url: "http://file.innopinenut.com/" + val.cover
        });
      }
      this.formData = {
        articleId: val.articleId,
        logoId: val.logoId,
        typeId: val.typeId,
        publishUserName: val.publishUserName,
        sourceType: val.sourceType,
        title: val.title,
        content: val.content,
        topicIdArr: val.topicIdArr,
        urlMsg: val.urlMsg,
        brief: val.brief,
        tags: val.tags,
        upAction: val.upAction,
        upDateMsg: uptime(val.upTime),
        sort: val.sort,
        cover: val.cover
      }
    }
  },
  mounted() { },

  methods: {
    sc(value) {
      this.formData.tags = value.replace(/，/ig, ',')
      console.log(this.formData.tags);
    },

    change123(val) {
      this.formData.upDateMsg = val
      console.log(this.formData.upDateMsg);
    },
    selectChange1(value) {
      this.formData.topicIdArr = value
      console.log(this.formData.topicIdArr);
    },
    upchange(value) {
      this.formData.upAction = value
      console.log(this.formData.upAction);
    },

    async getTopics() {
      let { data } = await getTopics({
        type: 1,
        page: 1,
        pageSize: 100
      })
      this.res = data
      console.log(this.res);

    },
    async getList() {
      let res = await getTypes({
        typeGroup: "ARTICLE",
      });
      console.log(res);

      this.options = res.data;
    },
    selectChange(value) {
      this.formData.typeId = value;
      console.log(this.formData.typeId);
    },
    async change(val) {
      console.log(val);
      this.formData.sourceType = Number(val);
    },
    // 返回上一级页面
    goBack() {
      this.$router.go(-1);
    },
    // 确认
    async btnOK() {
      this.$refs.perForm.validate().then(async () => {

        if (this.formData.articleId) {

          let { code, msg } = await modifyArticle(this.formData);
          if (this.formData.articleId && code == 0) {
            this.$message.success("编辑成功");
          } else {
            console.log(msg);
            this.$message({
              type: "error",
              message: msg,
            });
          }
          this.goBack();
        } else {

          let { code, msg } = await addArticle(this.formData);
          console.log(code, msg);
          if (code == 0) {
            this.$message.success("新增成功");
          } else {
            console.log(msg);
            this.$message({
              type: "error",
              message: msg,
            });
          }
          this.goBack();
        }
      });

    },

    //弹窗取消函数
    btnCancel() {
      this.goBack();
    },


    // 上传图片方法
    handleAvatarSuccess(res, file) {
      console.log("上传成功", res, file);
      this.imageUrl = "http://file.innopinenut.com/" + res.key;
      this.formData.logoId = res.key;
      this.$message.success('上传成功')

    },
    async beforeAvatarUpload(file) {
      // console.log("上传前");
      let { data } = await getUploadToken();
      // console.log(data);
      if (data == "" || data == null) {
        this.message.error("上传图片时获取Token失败！");
        return;
      }
      this.postData.token = data.token;
      this.postData.key = data.fid;
    },
    // 删除图片
    handleRemove(file) {
      let key = file.url.split('/')[3]
      if (key === this.formData.logoId) {
        this.formData.logoId = ''
      }
    },

    // 上传封面
    handleAvatarSuccess1(res, file) {
      console.log("上传成功", res, file);
      this.imageUrl1 = "http://file.innopinenut.com/" + res.key;
      this.formData.cover = res.key;
      this.$message.success('上传成功')

    },
    // 删除图片
    handleRemove1(file) {
      console.log(file);
      let key = file.url.split('/')[3]
      if (key === this.formData.cover) {
        this.formData.cover = ''
      }
    },
    async beforeAvatarUpload1(file) {
      // console.log("上传前");
      let { data } = await getUploadToken();
      // console.log(data);
      if (data == "" || data == null) {
        this.message.error("上传图片时获取Token失败！");
        return;
      }
      this.postData1.token = data.token;
      this.postData1.key = data.fid;
    },

  },
};
function uptime(nS) {
  let date = new Date(nS);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let h = date.getHours();
  let m = date.getMinutes();
  h = h < 10 ? ('0' + h) : h;
  m = m < 10 ? ('0' + m) : m;

  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  date = year + '-' + month + '-' + day + ' ' + h + ':' + m
  // 2018-10-09
  return date;
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>